package render

import kotlinx.browser.window
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLShader
import org.khronos.webgl.WebGLUniformLocation

class ShaderProgram(private val shader: Shader, private val gl: WebGLRenderingContext) {
    var shaderProgramInternal: WebGLProgram? = null
    var vertexPosition: Int? = null
    var projectionMatrix: WebGLUniformLocation? = null
    var modelViewMatrix: WebGLUniformLocation? = null

    fun initShaderProgram() {
        val vertexShader = loadShader(WebGLRenderingContext.VERTEX_SHADER, shader.vsSource)
        val fragmentShader = loadShader(WebGLRenderingContext.FRAGMENT_SHADER, shader.fsSource)

        val shaderProgram = gl.createProgram()
        gl.attachShader(shaderProgram, vertexShader)
        gl.attachShader(shaderProgram, fragmentShader)
        gl.linkProgram(shaderProgram)

        shaderProgramInternal = shaderProgram
        vertexPosition = gl.getAttribLocation(shaderProgram,"aVertexPosition")
        projectionMatrix = gl.getUniformLocation(shaderProgram, "uProjectionMatrix")
        modelViewMatrix = gl.getUniformLocation(shaderProgram, "uModelViewMatrix")
    }

    fun loadShader(type: Int, source: String): WebGLShader? {
        val shader = gl.createShader(type)
        gl.shaderSource(shader, source)

        gl.compileShader(shader)

        if (gl.getShaderParameter(shader, WebGLRenderingContext.COMPILE_STATUS) == null) {
            window.alert("An error occurred compiling the shaders: " + gl.getShaderInfoLog(shader));
        }

        return shader
    }
}
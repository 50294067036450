import `object`.ObjLoader
import `object`.Object
import libraries.glMatrix.mat4
import libraries.glMatrix.vec3
import kotlinx.browser.document
import kotlinx.browser.window
import org.khronos.webgl.Float32Array
import org.khronos.webgl.WebGLBuffer
import org.w3c.dom.HTMLCanvasElement
import render.Shader
import render.ShaderProgram
import kotlin.math.PI
import org.khronos.webgl.WebGLRenderingContext as GL


suspend fun main() {
    val testObject = ObjLoader.loadObj("untitled")
    document.body?.onload = {
        val html = WebGlWrapper(testObject)
        window.requestAnimationFrame { html.setup() }
    }
}


class WebGlWrapper(val testObject: Object) {
    val canvas = document.getElementById("mycanvas") as HTMLCanvasElement
    var webgl: GL = canvas.getContext("webgl") as GL

    val shaderProgram = ShaderProgram(Shader(), webgl)


    init {
        webgl.enable(GL.DEPTH_TEST)
        shaderProgram.initShaderProgram()
    }

    fun setup() {
        // Set clear color to black, fully opaque
        webgl.clearColor(0.0f, 0.0f, 0.0f, 1.0f);
        // Clear the color buffer with specified clear color
        webgl.clear(GL.COLOR_BUFFER_BIT);

        testObject.initBuffers(webgl)
        render()
    }

    fun render() {
        webgl.clearColor(0.0f, 0.0f, 0.0f, 1.0f)
        webgl.clearDepth(1.0f)
        webgl.enable(GL.DEPTH_TEST)
        webgl.depthFunc(GL.LEQUAL)

        webgl.clear(GL.COLOR_BUFFER_BIT or GL.DEPTH_BUFFER_BIT)

        val fov = 45 * PI.toFloat() / 180
        val aspect = webgl.canvas.clientWidth / webgl.canvas.clientHeight
        val zNear = 0.1f
        val zFar = 100.0f
        val projectionMatrix = mat4.create()

        mat4.perspective(projectionMatrix, fov, aspect.toFloat(), zNear, zFar)

        testObject.predraw(webgl, shaderProgram)

        webgl.useProgram(shaderProgram.shaderProgramInternal)

        webgl.uniformMatrix4fv(shaderProgram.projectionMatrix, false, projectionMatrix)

        testObject.draw(webgl, shaderProgram)

        window.setTimeout({
            window.requestAnimationFrame { render() }
        }, 1000 / 25)
    }
}
package `object`

import libraries.glMatrix.vec3
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlin.js.Promise

object ObjLoader {

    suspend fun loadObj(name: String): Object {
        val response = window
            .fetch("/object/${name}.obj")
            .await()
            .text()
            .await()

        val vertices = ArrayList<Float>()
        val indices = ArrayList<Short>()
        response.split("\n").forEach { line ->
            val values = line.split(' ').filter { it != "" }
            when(values.getOrNull(0)) {
                "v" -> {
                    for (i in 1 .. 3) {
                        vertices.add(values[i].toFloat())
                    }
                }
                "f" -> {
                    for (i in 1 .. 3) {
                        indices.add((values[i].split("/")[0].toShort()).toShort())
                    }
                }
            }
        }

        console.log(indices.size)

        return Object(vertices.toTypedArray(), indices.toTypedArray())
//        return Object(verticesX, indicesX)
    }
}

package `object`

import libraries.glMatrix.*
import libraries.glMatrix.mat4
import libraries.glMatrix.vec3
import org.khronos.webgl.Float32Array
import org.khronos.webgl.Uint16Array
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.WebGLRenderingContext as GL
import render.ShaderProgram

class Object (val vertices: Array<Float>, val indices: Array<Short>) {
    var positionBuffer: WebGLBuffer? = null
    var indexBuffer: WebGLBuffer? = null

    val position = vec3(-0.5f, 0.5f, 1f)
    val rotation = vec3(0f, 0f, 0f)

    fun initBuffers(webgl: GL) {
        positionBuffer = webgl.createBuffer()

        webgl.bindBuffer(GL.ARRAY_BUFFER, positionBuffer)
        webgl.bufferData(GL.ARRAY_BUFFER, Float32Array(vertices), GL.STATIC_DRAW)

        indexBuffer = webgl.createBuffer()
        webgl.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, indexBuffer)
        webgl.bufferData(
            GL.ELEMENT_ARRAY_BUFFER,
            Uint16Array(indices),
            GL.STATIC_DRAW
        );
    }

    fun predraw(webgl: GL, shaderProgram: ShaderProgram) {
        webgl.bindBuffer(GL.ARRAY_BUFFER, positionBuffer)
        webgl.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, indexBuffer)


        shaderProgram.vertexPosition?.let {
            webgl.vertexAttribPointer(it, 3, GL.FLOAT, false, 0, 0)
            webgl.enableVertexAttribArray(it)
        }
    }

    fun draw(webgl: GL, shaderProgram: ShaderProgram) {
        val modelViewMatrix = mat4.create()

        mat4.translate(modelViewMatrix, modelViewMatrix, position)
//        mat4.rotate(modelViewMatrix, modelViewMatrix, 0f, vec3(0f,0f,0f))

        webgl.uniformMatrix4fv(shaderProgram.modelViewMatrix, false, modelViewMatrix)

        webgl.bindBuffer(GL.ARRAY_BUFFER, positionBuffer)

        webgl.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, indexBuffer);

        webgl.drawElements(GL.TRIANGLES, indices.size, GL.UNSIGNED_SHORT, 0)
    }
}